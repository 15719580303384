<template>
  <div style="font-family: 'Noto Sans Khmer', roboto, sans-serif;">
    <v-row style="background-color: rgb(0 50 0); color: white">
      <v-container>
        <div
          style="
            display: flex;
            margin-bottom: 20px;
            margin-top: 20px;
            align-items: center;
            padding: 12px;
          "
        >
          <a
            href="/"
            target="_blank"
            ref="noopener"
            class="no-underline"
          >
            <div style="display: flex; align-items: center">
              <v-img
                src="../assets/bayonlogo.png"
                style="width: 58px; height: 58px"
              ></v-img>
              <span class="text-h6 ml-5" style="font-weight: bold; color: white"
                >BAYON APP</span
              >
            </div>
          </a>
          <v-spacer></v-spacer>
          <div>
            <span
              @click="changeLanguage('en')"
              class="lang-cursor-pointer"
              :style="
                $i18n.locale === 'en'
                  ? 'text-decoration: underline; color: #faee1c'
                  : ''
              "
              >EN
            </span>
            |
            <span
              @click="changeLanguage('km')"
              class="lang-cursor-pointer"
              :style="
                $i18n.locale === 'km'
                  ? 'text-decoration: underline; color: #faee1c'
                  : ''
              "
            >
              KH</span
            >
          </div>
        </div>
        <div style="padding: 12px">
          <v-row style="align-items: center" justify="space-around">
            <v-col
              cols="12"
              lg="5"
              :class="$vuetify.breakpoint.mobile ? 'text-center' : ''"
            >
              <h1 class="text-h3">
                Download
                <span style="color: #faee1c">{{ countryName }}</span> realestate
                marketplace
              </h1>
              <br />
              <v-row
                :justify="$vuetify.breakpoint.mobile ? 'center' : 'start'"
                class="ml-0"
              >
                <a
                  href="https://play.google.com/store/apps/details?id=com.buyhousing.online&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><v-img
                    alt="Get it on Google Play"
                    src="../assets/playstore.png"
                    contain
                    :width="$vuetify.breakpoint.mobile ? '150' : '200'"
                    :height="$vuetify.breakpoint.mobile ? '80' : '100'"
                  ></v-img
                ></a>
                <a
                  href="https://apps.apple.com/kh/app/bayon-app/id1546388328"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <v-img
                    src="../assets/appstore.png"
                    contain
                    alt="Available on App Store"
                    :width="$vuetify.breakpoint.mobile ? '150' : '200'"
                    :height="$vuetify.breakpoint.mobile ? '80' : '100'"
                    style="margin-left: 10px"
                  ></v-img>
                </a>
              </v-row>
            </v-col>
            <v-col cols="12" lg="5" class="text-center">
              <video
                width="100%"
                height="100%"
                style="border: 1px solid #fff"
                controls
                controlsList="nodownload"
              >
                <source
                  src="https://firebasestorage.googleapis.com/v0/b/bayonapp-2021.appspot.com/o/banner%2Fdownload_app_intro.mp4?alt=media&token=42e3bc74-1035-497d-af41-9259c23da70b"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-row>
    <v-row>
      <v-container>
        <v-col cols="12">
          <div>
            <div class="text-center margin-top-bottom">
              <h2 class="no-bold des-color">
                {{ $t("downloadPageDes") }}
              </h2>
            </div>
            <v-divider></v-divider>
            <div class="margin-top-bottom">
              <v-row justify="space-around">
                <v-col cols="12" md="4">
                  <h2 class="no-bold mb-5 title-color">
                    {{ $t("downloadPageSpecial") }}
                  </h2>
                  <ul class="des-color" style="list-style-type: circle">
                    <li>{{ $t("downloadPageSpecial1") }}</li>
                    <li>{{ $t("downloadPageSpecial2") }}</li>
                    <li>{{ $t("downloadPageSpecial3") }}</li>
                    <li>{{ $t("downloadPageSpecial4") }}</li>
                    <li>{{ $t("downloadPageSpecial5") }}</li>
                  </ul>
                </v-col>
                <v-col cols="12" md="4">
                  <h2 class="no-bold mb-5 title-color">
                    {{ $t("downloadPageConvenience") }}
                  </h2>
                  <ul class="des-color" style="list-style-type: circle">
                    <li>{{ $t("downloadPageConvenience1") }}</li>
                    <li>{{ $t("downloadPageConvenience2") }}</li>
                    <li>{{ $t("downloadPageConvenience3") }}</li>
                    <li>{{ $t("downloadPageConvenience4") }}</li>
                    <li>{{ $t("downloadPageConvenience5") }}</li>
                    <li>{{ $t("downloadPageConvenience6") }}</li>
                    <li>{{ $t("downloadPageConvenience7") }}</li>
                  </ul>
                </v-col>
              </v-row>
            </div>
            <v-divider></v-divider>
            <!-- ============Benefits============ -->
            <div class="margin-top-bottom">
              <v-row
                justify="center"
                style="align-items: center"
                class="text-center"
              >
                <v-col cols="12" md="5">
                  <v-img
                    src="../assets/downloadAgent.jpg"
                    alt="Estate Agent Benefits"
                  ></v-img>
                </v-col>
                <v-col cols="12" md="7">
                  <h2
                    class="no-bold mb-5 text-center title-color"
                  >
                    {{ $t("downloadPageBenefitForAgent") }}
                  </h2>
                  <p class="des-color">
                    {{ $t("downloadPageBenefitForAgentDes") }}
                  </p>
                </v-col>
              </v-row>
            </div>
            <v-divider></v-divider>
            <div class="margin-top-bottom">
              <v-row
                
                justify="center"
                style="text-align: center; align-items: center"
              >
                <v-col cols="12" md="7" :order="$vuetify.breakpoint.mobile? 'last' : 'first'">
                  <h2
                    class="no-bold mb-5 text-center title-color"
                  >
                    {{ $t("downloadPageBenefitForCompany") }}
                  </h2>
                  <p class="des-color">
                    {{ $t("downloadPageBenefitForCompanyDes") }}
                  </p>
                </v-col>
                <v-col cols="12" md="5">
                  <v-img
                    src="../assets/downloadCompany.jpg"
                    alt="Estate Company Benefits"
                  ></v-img>
                </v-col>
              </v-row>
            </div>
            <v-divider></v-divider>
            <!-- ===============Footer============== -->
            <div class="des-color text-center" style="margin: 40px 0 20px 0">
              {{ $t("downloadPageFooter") }} <br />
              {{ $t("downloadPageContact") }}<br />
              <v-icon color="green">mdi-phone</v-icon> 099-262715, 010-262715
              <br />
              <a
                href="https://www.facebook.com/bayonapp.estate"
                target="_blank"
                ref="noopener"
                class="no-underline"
                ><v-icon color="primary" size="30">mdi-facebook</v-icon></a
              >
              <a
                href="https://www.youtube.com/channel/UC69zVpCKFqBnsq-pawP3i8g"
                target="_blank"
                ref="noopener"
                class="no-underline"
                ><v-icon color="red" size="30" class="ml-5"
                  >mdi-youtube</v-icon
                ></a
              >
            </div>
          </div>
        </v-col>
      </v-container>
    </v-row>
  </div>
</template>

<script>
import { countyMobileList } from "@/libs/countryMobileWithFlag";

export default {
  name: "Download",
  mounted() {
    this.$jQuery("body").off();
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
  },
  data() {
    return {
      countryName: "Cambodia",
    };
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      this.$vuetify.lang.current = this.$i18n.locale;
    },
  },
  created() {
    let vm = this;
    document.title = "Download realestate marketplace";
    if (!(localStorage.country !== undefined && localStorage.country !== "")) {
      vm.$jQuery.get(
        "https://www.cloudflare.com/cdn-cgi/trace",
        function (data) {
          if (data) {
            vm.$store.state.country = data.split("loc=")[1].substr(0, 2);
            localStorage.country = vm.$store.state.country;
            let cDoc = countyMobileList.find((o) => {
              return (
                o.value === vm.$store.state.country ||
                o.number === localStorage.country
              );
            });
            localStorage.countryCode = cDoc.value;
            vm.countryName = cDoc.name;
          }
        }
      );
    } else {
      let cDoc = countyMobileList.find((o) => {
        return (
          o.value === localStorage.country || o.number === localStorage.country
        );
      });
      localStorage.countryCode = cDoc.value;
      vm.countryName = cDoc.name;
    }
    if(vm.countryName === 'Cambodia'){
      this.$i18n.locale = 'km'
    }else{
      this.$i18n.locale = 'en'
    }
  },
};
</script>
<style scoped>
@media (min-width: 1904px) {
  .container {
    max-width: 1285px !important;
  }
}
.lang-cursor-pointer {
  cursor: pointer;
  display: inline-block;
}
.no-bold {
  font-weight: 400;
}
.no-underline {
  text-decoration: none
}
.margin-top-bottom {
  margin: 40px 0 40px 0
}
.title-color {
  color: #6c2c73;
}
.des-color {
  color: #636e77;
}
.text-center {
  text-align: center
}
</style>